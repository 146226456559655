.upload-btn {
  height: 40px;
}
.upload-btn .el-upload {
  height: 40px !important;
  border: none !important;
}
.addtestPaper .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
}
